<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline text-left white--text"
              >Timesheet Details</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <template>
          <v-data-table
            :headers="headers"
            :items="timesheetEntriesResult"
            :items-per-page="10"
            class="pt-4"
            show-group-by
            group-by="EntryDate"
            dense
          >
            <template v-slot:item.EntryHours="{item}">{{
              item.EntryHours | decimalToTime
            }}</template>
          </v-data-table>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
export default {
  props: {
    timeSheetId: Number,
  },
  filters: {
    decimalToTime(d) {
      var decimalTime = parseFloat(d) * 60 * 60
      var hours = Math.floor(decimalTime / (60 * 60))

      decimalTime = decimalTime - hours * 60 * 60
      var minutes = Math.round(decimalTime / 60)

      if (minutes < 10) {
        minutes = '0' + minutes
      }

      return hours + ':' + minutes
    },
  },
  data() {
    return {
      timesheetEntriesResult: [],
      headers: [
        {
          text: 'Date',
          align: 'center',
          sortable: 'true',
          value: 'EntryDate',
        },
        {
          text: 'Project Number',
          align: 'center',
          sortable: 'true',
          value: 'ProjectNumber',
        },
        {
          text: 'Project Description',
          align: 'center',
          sortable: 'true',
          value: 'ProjectDescription',
        },
        {
          text: 'Activity',
          align: 'center',
          sortable: 'true',
          value: 'ActivityCode',
        },
        {
          text: 'Code Description',
          align: 'center',
          sortable: 'true',
          value: 'ActivityCodeDescription',
        },
        {
          text: 'Note',
          align: 'center',
          sortable: 'true',
          value: 'Note',
        },
        {
          text: 'Hours',
          align: 'center',
          sortable: 'true',
          value: 'EntryHours',
        },
      ],
    }
  },
  created() {
    this.getTimesheetEntriesResult()
    eventBus.$on('refreshTimesheetDetails', () => {
      this.getTimesheetEntriesResult()
    })
  },
  beforeDestroy() {
    eventBus.$off('refreshTimesheetDetails')
  },
  watch: {
    timeSheetId: {
      handler(nval, oval) {
        this.getTimesheetEntriesResult()
      },
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    getTimesheetEntriesResult() {
      if (this.timeSheetId) {
        let url = `/TimesheetEntry/TimeSheet/${this.timeSheetId}`
        this.$axios.get(url).then(
          (res) => {
            this.timesheetEntriesResult = res.data
          },
          (error) => {
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      } else {
        this.timesheetEntriesResult = []
      }
    },
  },
}
</script>
<style scoped>
</style>