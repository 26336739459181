<template>
  <v-text-field
    :value="hoursTimeFormatted"
    @change="hoursTimeFormatted = $event"
    :label="label"
    :disabled="disabled"
    :placeholder="placeholder"
    :rules="[rules.hoursGreaterThanZero, rules.hoursValidTime]"
    :readonly="readonly"
  ></v-text-field>
</template>
<script>
import Rules from '@validation/rules'
export default {
  model: {
    prop: 'hours',
    event: 'change',
  },
  props: {
    label: String,
    disabled: Boolean,
    placeholder: String,
    hours: Number,
    readonly: Boolean,
  },
  data() {
    return {
      rules: Rules,
    }
  },

  computed: {
    hoursTimeFormatted: {
      get() {
        if (this.hours && this.hours >= 0) {
          return this.decimalToTime(this.hours)
        }
      },
      set(newValue) {
        let originalValue = this.hours
        if (newValue.includes(':')) {
          let time = newValue.split(':')
          let h = time[0].replace(/[^0-9.]/g, '')
          let hours = 0
          if (h.length > 0) {
            hours = parseInt(h, 10)
          }
          let m = time[1].replace(/[^0-9]/g, '')
          let minutes = 0
          //if they enter hours:minutes like 1:3 we are going to assume they mean 1:30 not 1:03
          if (m.length > 1) {
            minutes = parseInt(m, 10)
          } else if (m.length > 0) {
            minutes = parseInt(m.toString() + '0', 10)
          }
          //make sure that it was able to parse
          if (
            hours >= 0 &&
            (minutes == 0 || minutes == 15 || minutes == 30 || minutes == 45)
          ) {
            let dec = minutes / 60

            let newTime = hours + dec
            //if they enter the same time (possible with extra characters) for their previous entry
            // we need to reset the property so that it displays the hours again
            if (originalValue == newTime) {
              this.$emit('change', -1)
              this.$nextTick(() => {
                this.$emit('change', newTime)
              })
            } else {
              this.$emit('change', newTime)
            }
          } else {
            // we have an invalid time.  changing it to -1 and back on next tick resets it to the previous value
            this.$emit('change', -1)
            this.$nextTick(() => {
              this.$emit('change', originalValue)
            })
          }
        } else {
          let newTime = parseFloat(newValue.replace(/[^0-9.]/g, ''))
          let partial = (newTime % 1).toFixed(2)
          if (
            newTime &&
            newTime >= 0 &&
            (partial == 0.0 ||
              partial == 0.25 ||
              partial == 0.5 ||
              partial == 0.75)
          ) {
            //if they enter the corresponding decimal for their previous entry
            // we need to reset the property so that it displays the hours again
            if (originalValue == newTime) {
              this.$emit('change', -1)
              this.$nextTick(() => {
                this.$emit('change', newTime)
              })
            } else {
              this.$emit('change', newTime)
            }
          } else {
            // we have an invalid number.  changing it to -1 and back on next tick resets it to the previous value
            this.$emit('change', -1)
            this.$nextTick(() => {
              this.$emit('change', originalValue)
            })
          }
        }
      },
    },
  },
  methods: {
    decimalToTime(d) {
      var decimalTime = parseFloat(d) * 60 * 60
      var hours = Math.floor(decimalTime / (60 * 60))

      decimalTime = decimalTime - hours * 60 * 60
      var minutes = Math.round(decimalTime / 60)

      if (minutes < 10) {
        minutes = '0' + minutes
      }
      return hours + ':' + minutes
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>