import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VListItem,{staticClass:"primary"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline text-left white--text"},[_vm._v("Timesheet Details")])],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"headers":_vm.headers,"items":_vm.timesheetEntriesResult,"items-per-page":10,"show-group-by":"","group-by":"EntryDate","dense":""},scopedSlots:_vm._u([{key:"item.EntryHours",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("decimalToTime")(item.EntryHours)))]}}])})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }